import {NotificationManager} from 'react-notifications';
interface Props {
    type:string
    message: string
    title: string
    timeOut: number
    callback:Function,
    priority:boolean
}


const createNotification = (props:any) => {
    //console.log('createnotification', props)
      const {type, message, title, timeOut, callback, priority} = props
        
      switch (type) {
        case 'info':
          NotificationManager.info(message);
          break;
        case 'success':
          NotificationManager.success(message, title);
          break;
        case 'warning':
          NotificationManager.warning(message, title, timeOut);
          break;
        case 'error':
          NotificationManager.error(message, title, timeOut, callback);
          break;
      
    };}

    const Notifications = (props:Props)=>{
        createNotification(props)

    }
    export default Notifications