import './metamask.styles.css'
import { useState, useEffect } from 'react';
import LoginButton from './LoginButton';
import DisconnectButton from './DisconnectButton';
import AccountInfo from './AccountInfo';
import doLogin from './Login';
import disconnect from './Disconnect';

export default function Metamask() {
  const [account, setAccount] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const storedAccount = localStorage.getItem("wallet");
    if (storedAccount) {
      setAccount(storedAccount);
    }
  }, []);

  const handleLoginClick = async () => {
    try {
      if (!account) {
        const newAccount = await doLogin();
        setAccount(newAccount.account);
      }
    } catch (err) {
      return alert(err);
    }
  };

  const handleDisconnectClick = async () => {
    try {
      await disconnect();
      setAccount("");
    } catch (err) {
      return alert(err);
    }
  };

  const handleMouseOver = () => {
    setShowDropdown(true);
  };

  const handleMouseOut = () => {
    setShowDropdown(false);
  };

  return (
    <div className="metamask-button" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {account ? 
        <AccountInfo account={account} />
      :
        <LoginButton onClick={handleLoginClick}/>
      }
      
      {account && showDropdown && (
        <div className="dropdown">
          <DisconnectButton onClick={handleDisconnectClick} />
        </div>
      )}
    </div>
  );
}