// src/Canvas.js
import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import Notifications from './notifications/Notifications';
//import icone from './../assets/images/luck creature-idle.png';
import icone from './../assets/sprites/luffyparadodireita.png';
//import icone1 from './../assets/images/luck creature-run.png';
import icone1 from './../assets/sprites/luffyandandodireita.png';
//import icone_left from './../assets/images/luck creature-run-left.png';
import icone_left from './../assets/sprites/luffyandandoesquerda.png';
import icone_left_paused from './../assets/sprites/luffyparadoesquerda.png';
import Cenario from './../assets/cenario/ruinsd.png';
import Chuva from './../assets/cenario/chuva.gif';

import { time } from 'console';
interface CanvasProps {
    user: string;
    socket: any;
    color: any;
    confRef: any;
}

var image: any = new Image();
var requestAnimationId: any
var canvas: any
let ctx: any


let frameCount = 0;


// Dimensões do canvas
var canvasWidth = 576 * 2;
var canvasHeight = 288 * 2;
var positions: any = {}
var read = false;
var lastPosition: any = {}
const Canvas = forwardRef(({ user, socket, color, confRef }: CanvasProps, ref) => {

    //const icone = {width:48,height:48}
    const canvasRef: any = useRef(null);
    const gameDivRef: any = useRef(null);
    const [ballons, setBallons] = useState<any>({});
    const [isRain, setIsRain] = useState<any>(false);


    // Carregar imagens dos sprites
    const idleSprite = new Image();
    const runSprite = new Image();
    const runSpriteLeft = new Image();
    const idleSpriteLeft = new Image();
    const cenarioLayer = new Image();
    const chuvaLayer = new Image();
    //const landLolli = new Image();

    idleSprite.style['imageRendering'] = 'pixelated';
    runSprite.style['imageRendering'] = 'pixelated';
    runSpriteLeft.style['imageRendering'] = 'pixelated';
    idleSpriteLeft.style['imageRendering'] = 'pixelated';
    cenarioLayer.style['imageRendering'] = 'pixelated';
    chuvaLayer.style['imageRendering'] = 'pixelated';
    //landLolli.style['imageRendering'] = 'pixelated';

    // Ajuste o caminho das imagens conforme a localização correta no seu projeto
    idleSprite.src = icone;
    runSprite.src = icone1;
    runSpriteLeft.src = icone_left;
    idleSpriteLeft.src = icone_left_paused;
    cenarioLayer.src = Cenario;
    chuvaLayer.src = Chuva;
    //landLolli.src = land;

    // Dimensões dos sprites
    const idleSpriteWidth = 104 * 48;  // Largura total do sprite idle (4 quadros)
    const runSpriteWidth = 40 * 48;   // Largura total do sprite run (6 quadros)
    const spriteHeight = 48;      // Altura do sprite

    // Dimensões de cada frame do sprite
    const idleFrameWidth = idleSpriteWidth / 104; // 4 quadros de idle
    const runFrameWidth = runSpriteWidth / 40;   // 6 quadros de run



    // Propriedades do personagem
    const player = {
        colision: false,
        x: 425,
        y: 178,
        vx: 0, // Velocidade no eixo x
        vy: 0, // Velocidade no eixo y
        speed: 0.7,   // Aceleração
        maxSpeed: 3,  // Velocidade máxima
        friction: 0.9, // Atrito para desaceleração
        frameX: 0,  // Quadro atual no eixo X do sprite
        frameY: 0,  // Quadro atual no eixo Y do sprite
        animationSpeed: 6,  // Velocidade da animação
        currentSprite: 'idleSprite',  // Sprite atual
        currentFrameWidth: idleFrameWidth, // Largura atual do frame
        totalFrames: 104,  // Total de quadros no sprite atual
        frameCounter: 0,  // Contador para controlar a mudança de frames
        isMoving: false,  // Se o personagem está se movendo
        facingRight: true  // Definir se o personagem está olhando para a direita
    };

    // Movimentos do personagem
    const keys: any = {
        ArrowUp: false,
        ArrowDown: false,
        ArrowLeft: false,
        ArrowRight: false
    };

    // lista de obstaculos que podem ser colididos
    const obstaculos = [
        { x: 275, y: 45, width: 40, height: 20 },
        { x: 390, y: 22, width: 35, height: 25 },
        { x: 505, y: 19, width: 33, height: 15 },
        { x: 582, y: 75, width: 38, height: 15 },
        { x: 645, y: 50, width: 38, height: 20 },
        { x: 760, y: 150, width: 60, height: 20 },
        /* {x: 759, y: 233, width: 29, height:15},
        {x: 791, y: 223, width: 29, height:15}, */
        { x: 920, y: 230, width: 38, height: 15 },
        { x: 930, y: 380, width: 20, height: 10 },
        { x: 826, y: 420, width: 60, height: 20 },
        { x: 702, y: 475, width: 44, height: 20 },
        { x: 642, y: 440, width: 40, height: 20 },
        { x: 706, y: 290, width: 20, height: 40 },
        //esquerda
        { x: 310, y: 560, width: 30, height: 15 },
        { x: 338, y: 543, width: 30, height: 15 },
        { x: 250, y: 530, width: 95, height: 15 },
        { x: 315, y: 513, width: 20, height: 10 },

        { x: 277, y: 436, width: 40, height: 20 },
        { x: 109, y: 446, width: 70, height: 20 },
        { x: 48, y: 432, width: 24, height: 15 },
        { x: 20, y: 420, width: 20, height: 15 },
        { x: 342, y: 362, width: 40, height: 15 },
        { x: 390, y: 340, width: 40, height: 25 },
        { x: 216, y: 313, width: 30, height: 25 },
        { x: 250, y: 315, width: 30, height: 25 },
        { x: 212, y: 210, width: 20, height: 100 },
        { x: 187, y: 112, width: 30, height: 20 },
        //portal
        { x: 265, y: 250, width: 170, height: 25 },
        { x: 490, y: 254, width: 230, height: 25 },
        //limites
        { x: 735, y: 0, width: 20, height: 70 },
        { x: 735, y: 45, width: 90, height: 20 },
        { x: 860, y: 70, width: 20, height: 120 },
        { x: 860, y: 180, width: 90, height: 20 },
        { x: 990, y: 210, width: 20, height: 240 },
        { x: 930, y: 430, width: 20, height: 40 },
        { x: 800, y: 485, width: 105, height: 20 },
        { x: 800, y: 520, width: 20, height: 60 },
        //esquerda
        { x: 200, y: 0, width: 20, height: 70 },
        { x: 150, y: 50, width: 70, height: 20 },
        { x: 145, y: 70, width: 20, height: 100 },
        { x: 100, y: 180, width: 20, height: 20 },
        { x: 80, y: 210, width: 20, height: 180 },
        { x: 30, y: 365, width: 40, height: 20 },

    ]

    // Variável para controlar o deltaTime
    let lastTime = 0;

    // Atualizar posição do jogador e controlar as animações
    function updatePlayerPosition(deltaTime: number) {

        //console.log(player)
        player.isMoving = false;

        // Aceleração com base nas teclas pressionadas
        if (keys.ArrowUp) {
            player.vy -= player.speed * deltaTime;
            player.isMoving = true;
        }
        if (keys.ArrowDown) {
            player.vy += player.speed * deltaTime;
            player.isMoving = true;
        }
        if (keys.ArrowLeft) {
            player.vx -= player.speed * deltaTime;
            player.isMoving = true;
            player.facingRight = false;
        }
        if (keys.ArrowRight) {
            player.vx += player.speed * deltaTime;
            player.isMoving = true;
            player.facingRight = true;
        }

        // Aplicar atrito para desaceleração suave
        player.vx *= player.friction;
        player.vy *= player.friction;

        // Limitar a velocidade máxima
        player.vx = Math.max(-player.maxSpeed, Math.min(player.vx, player.maxSpeed));
        player.vy = Math.max(-player.maxSpeed, Math.min(player.vy, player.maxSpeed));
        //
        const last = { x: player.x, y: player.y }
        // Atualizar posição
        player.x += player.vx;
        player.y += player.vy;

        // Manter o personagem dentro dos limites do canvas
        if (player.x < player.currentFrameWidth / 2) player.x = player.currentFrameWidth / 2;
        if (player.y < - player.currentFrameWidth / 1.2) player.y = - player.currentFrameWidth / 1.2;

        if (player.x > canvasWidth - player.currentFrameWidth - 10) player.x = canvasWidth - player.currentFrameWidth - 10;
        if (player.y >= canvasRef.current.height - (spriteHeight * 1.7)) player.y = canvasRef.current.height - (spriteHeight * 1.7);

        // Mudar para o sprite de corrida se estiver se movendo 
        let colidiu = false
        if (player.isMoving) {
            player.currentSprite = 'runSprite';
            player.currentFrameWidth = runFrameWidth;
            player.totalFrames = 40; // Número de quadros no sprite de corrida
            player.animationSpeed = 4; // Aumentar a velocidade da animação ao correr


        } else {
            player.currentSprite = 'idleSprite';
            player.currentFrameWidth = idleFrameWidth;
            player.totalFrames = player.colision ? 104 : 4; // Número de quadros no sprite de idle
            player.animationSpeed = player.colision ? 5 : 8; // Diminuir a velocidade da animação ao estar parado
        }

        player.x = Math.round(player.x)
        player.y = Math.round(player.y)


        obstaculos.map((item: any, i) => {

            const verify = detectCollisionScene(player, item)
            if (verify) {
                const { x, y, currentFrameWidth } = player
                const obj = { x, y, currentFrameWidth, spriteHeight }
                /* console.log('colidiu', item)
                console.log('colidiu', obj) */
                colidiu = true
            }

        })

        if (colidiu) {
            player.x = last.x
            player.y = last.y
        }

    }

    function drawGlowEffect(x: any, y: any, width: any) {
        ctx.save();
        //ctx.fillStyle = 'rgba(255, 255, 0, 0.5)'; // Cor do retângulo com brilho
        ctx.shadowColor = 'rgba(255, 255, 0, 0.8)'; // Cor do brilho (amarelo)
        ctx.shadowBlur = 20; // Intensidade do brilho
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.strokeStyle = "rgba(251, 251, 0, .5)";
        ctx.lineWidth = 15;
        ctx.globalAlpha = 0.8;
        ctx.beginPath();
        ctx.roundRect(x + width / 2 - 5, y + spriteHeight / 3, width / 4, spriteHeight / 4, [50]);
        ctx.stroke();
        //ctx.fillRect(x+width/2-5, y+spriteHeight/3, width/4, spriteHeight/4); 
        /* ctx.globalAlpha = 0.4;
        ctx.strokeStyle = "orange";
        ctx.beginPath();
        ctx.roundRect(x-1, y-1, width/4+2, spriteHeight/4+2,[50]);
        ctx.stroke();
        ctx.globalAlpha = 0.2;
        ctx.strokeStyle = "orange";
        ctx.beginPath();
        ctx.roundRect(x-2, y-2, width/4+4, spriteHeight/4+4,[50]);
        ctx.stroke(); */
        ctx.globalAlpha = 1;
        ctx.shadowColor = ''; // Cor do brilho (amarelo)
        ctx.shadowBlur = 0; // Intensidade do brilho



        //console.log("Collision detected with " + ctx); 
        ctx.save();
        //ctx.restore();
    }

    function detectCollision(rect1: { x: number; currentFrameWidth: any; y: number; }, rect2: { x: number; currentFrameWidth: any; y: number; }) {
        return (
            rect1.x < rect2.x + rect2.currentFrameWidth / 4 &&
            rect1.x + rect1.currentFrameWidth / 4 > rect2.x &&
            rect1.y < rect2.y + spriteHeight / 4 &&
            rect1.y + spriteHeight / 4 > rect2.y
        );
    }
    function detectCollisionScene(rect1: { x: number; currentFrameWidth: number; y: number; }, rect2: { x: number; width: any; y: number; height: any; }) {
        // console.log( rect1.x + rect1.currentFrameWidth *1.8 , rect2.x)
        return (
            rect1.x + rect1.currentFrameWidth * 1.2 > rect2.x &&
            rect1.x < rect2.x + rect2.width - 20 &&
            rect1.y + spriteHeight * 1.8 > rect2.y &&
            rect1.y < rect2.y + rect2.height - 40
        );
    }

    // makeRoom
    function makeRoom(r1: string, r2: any) {
        if (r1.localeCompare(r2) < 0) {
            return { roomName: `${r1}${r2}`, pos: r1 };
        } else if (r1.localeCompare(r2) > 0) {
            return { roomName: `${r2}${r1}`, pos: r2 };
        } else {
            return { roomName: `${r1}${r2}`, pos: r1 };
        }
    }
    // Função para animar o sprite
    function animateSprite() {
        player.frameCounter++;
        if (player.frameCounter >= player.animationSpeed) {
            player.frameCounter = 0;
            player.frameX = (player.frameX + 1) % player.totalFrames;
        }

        let colision = false
        let tester: any = {}
        for (let id in positions) {

            const playerTester = positions[id].player;
            //console.log('playerTester', playerTester)
            if (user != positions[id].user && detectCollision(player, playerTester)) {
                //drawGlowEffect(playerTester.x, playerTester.y, playerTester.currentFrameWidth);
                //ctx.save();
                colision = true;
                tester = id
                break;
            }

        }
        if (colision) {
            if (!player.colision) {
                if (confRef.current) {
                    const result = makeRoom(socket.id, tester)
                    const guest = socket.id != result.pos ? true : false
                    socket.emit('joinroom', result.roomName);
                    const objToSend = {
                        roomName: result.roomName,
                        guest
                    }
                    confRef.current.joinPeer(objToSend);
                }
                player.colision = true;
                //console.log(colision)
            }


        } else {
            if (player.colision) {
                if (confRef.current) {

                    confRef.current.leavePeer({});
                }
            }
            player.colision = false;
        }
        makePosition()
        //console.log('player animateSprite', player)

    }

    function makeObstaculos() {
        obstaculos.map((item, i) => {
            ctx.fillStyle = 'rgba(255, 255, 0, 0.5)'; // Cor do retângulo com brilho
            ctx.fillRect(item.x, item.y, item.width, item.height);

        })
    }


    // **Função de desenho corrigida**
    function drawPlayer() {

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        // Salvar o estado atual do contexto

        ctx.save();
        for (let i in positions) {
            const data = positions[i];
            const player_current = data.user == user ? player : data.player;

            try {
                let sprite: any = ''
                if (!player_current?.facingRight) {
                    sprite = player_current.currentSprite == 'runSprite' ? runSpriteLeft : idleSpriteLeft
                } else {
                    sprite = player_current.currentSprite == 'runSprite' ? runSprite : idleSprite
                }
                ctx.drawImage(
                    sprite,
                    Math.round(player_current.frameX * player_current.currentFrameWidth),
                    Math.round(player_current.frameY * spriteHeight),
                    Math.round(player_current.currentFrameWidth),
                    Math.round(spriteHeight),
                    Math.round(player_current.x),
                    Math.round(player_current.y),
                    Math.round(player_current.currentFrameWidth * 1.8),
                    Math.round(spriteHeight * 1.8)
                );
                ctx.fillStyle = color; // Cor do retângulo com brilho
                ctx.font = "14px serif";
                ctx.textBaseline = "hanging";
                const text = ctx.measureText(data.user); // TextMetrics object
                ctx.shadowColor = 'rgba(36, 36, 33, 0.8)'; // Cor do brilho (amarelo)
                ctx.shadowBlur = 15; // Intensidade do brilho
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.fillText(data.user, player_current.x + (player_current.currentFrameWidth * 1.8 - text.width) / 2, player_current.y + spriteHeight * 1.8+3);




                // Desenhar normalmente sem transformação
                //ctx.setTransform(1, 0, 0, 1, 0, 0);


            } catch (error: any) {
                console.log('erro ao desenhar player', error)
            }



        }
        //ctx.scale(1, 1);

        ctx.restore();
        ctx.drawImage(cenarioLayer, 0, 0, canvasWidth, canvasHeight)
        //makeObstaculos() // para visualizar os marcadores
        ctx.save()
        // Restaurar o estado original do contexto

    }

    // Função principal de animação
    function gameLoop(timestamp: number) {
        let deltaTime = timestamp - lastTime;
        lastTime = timestamp;

        updatePlayerPosition(deltaTime / 16); // Normalizar o deltaTime
        animateSprite();
        //console.log('player configurado antes de criar ---', player)
        //if(player.currentSprite&&player.x&&player.y)

        drawPlayer();
        requestAnimationFrame(gameLoop);
    }



    useImperativeHandle(ref, () => ({
        makeBallonPosition,
    }));
    const makeBallonPosition = (pos: any) => {
        //console.log('ballon data received', pos.user)
        const ballonUser: any = pos.id;
        //console.log('player makeballon', ballonUser, positions)
        if (!positions[ballonUser]?.player) return;
        const newPosition = positions[ballonUser]?.player;
        const objToSend = {
            player: newPosition,
            color: pos.color,
            user: pos.user,
            message: pos.message,
            fadeOut: false,
            time: new Date().getTime(),
            clear: false
        }
        //console.log('makeBallonPosition objToSend', objToSend)

        setBallons((prevBallons: any) => {

            return ({ ...prevBallons, [ballonUser]: objToSend });
        })

    }
    const makePosition = () => {

        //console.log('makeposition')
        if (player.x == lastPosition.x && player.y == lastPosition.y) return
        lastPosition = { x: player.x, y: player.y }
        const objToSend = {
            player,
            color,
            user,
        }
        //console.log('send', objToSend)
        positions = { ...positions, [socket.id]: objToSend }
        socket.emit('position', objToSend);
    }




    socket.on('position', (data: any) => {
        //console.log('data position', data)

        /* const ballon: any = ballons[data.id]

        if (ballon?.user) {
            ballon.player = data.player
            const dif = new Date().getTime() - ballon.time
            //console.log(dif)
            if (dif && dif > 2000) {

                const ballonsCopy = { ...ballons }
                delete ballonsCopy[ballon.user]


                setBallons((prevBallons: any) => ({ ...prevBallons, [data.id]: { clear: true, time: new Date().getTime() } }))

            } else {
                setBallons((prevBallons: any) => ({ ...prevBallons, [data.id]: ballon }))
            }


        } */

        if (data.id != socket.id) {
            positions = { ...positions, [data.id]: data }
        }
    });

    socket.on('disconnectClient', (data: any) => {
        //console.log('disconnect', data)
        const item: any = positions[data]
        if (item) {
            const notData = {
                type: 'success',
                message: item.user + `, saiu!`,
                title: 'Lobbi',
                timeOut: 2000,
                callback: () => { },
                priority: true,
            }


            delete positions[data]
            delete ballons[data]
            Notifications(notData)
        }

    });

    // ----------- limit the number of events per second -----------------------

    const throttle = (callback: { apply: (arg0: null, arg1: IArguments) => void; }, delay: number) => {
        let previousCall = new Date().getTime();
        return function () {
            const time = new Date().getTime();

            if ((time - previousCall) >= delay) {
                previousCall = time;
                callback.apply(null, arguments);
            }
        };
    };

    useEffect(() => {
        if (gameDivRef.current) {
            gameDivRef.current.focus();
        }
    }, [])







    function resizeCanvas() {

        if (gameDivRef.current) {
            console.log('focus')
            gameDivRef.current.focus();
        }
        /*if (canvas) {
             canvas.width = window.innerWidth;
            canvas.height = 500;
            canvasWidth = window.innerWidth;
            canvasHeight = 500; 
            //console.log('resize')

            //ctx.clearRect(0, 0, canvas.width, canvas.height);


        }*/

        /**
         * Your drawings need to be inside this function otherwise they will be reset when 
         * you resize the browser window and the canvas goes will be cleared.
         */
        //drawStuff(); 
    }
    const makeKey = (e: any, cond: any) => {
        if (e.key in keys) {
            keys[e.key] = cond;
        }
    }
    var disparado = false
    useEffect(() => {
        if (isRain) {
            setTimeout((setIsRain) => {
                setIsRain(false)
            }, 2000 + Math.random() * 5000, setIsRain)
        } else if (!disparado) {
            disparado = true
            const timer = Math.random() * 15000 + 5000
            setTimeout((setIsRain) => {
                setIsRain(true)
                disparado = false
            }, timer, setIsRain)
        }
    }, [isRain])
    useEffect(() => {


        if (read) return;
        //console.log('entrou no canvasref')

        canvas = canvasRef.current;

        canvasWidth = 576 * 2;
        canvasHeight = 288 * 2;
        if (gameDivRef.current) {
            //console.log('focus')
            gameDivRef.current.focus();
        }

        if (canvas && !ctx) {
            canvasRef.current.width = canvasWidth
            canvasRef.current.height = canvasHeight

            //console.log('canvas ====>', canvas)
            ctx = canvas.getContext('2d');
            const notData = {
                type: 'success',
                message: `Você está conectado!`,
                title: 'Lobbi - Bem vindo',
                timeOut: 2000,
                callback: () => { },
                priority: true,
            }

            Notifications(notData)
            // Iniciar o loop do jogo após carregar as imagens
            idleSprite.onload = () => {
                //console.log('onload idleSprite')
                runSprite.onload = () => {
                    if (!read) {
                        read = true
                        console.log("iniciou =====> **********")
                        //requestAnimationFrame(gameLoop);
                        gameLoop(0)
                    }

                }
            };

        }
        // Controles do teclado

        function keyDownListener(event: { key: string | number; }) {
            makeKey(event, true)
        }
        //window.addEventListener('keydown', keyDownListener);

        function keyUpListener(event: { key: string | number; }) {
            makeKey(event, false)
        }
        //window.addEventListener('keyup', keyUpListener);



        //window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keydown', throttle(keyDownListener, 8), false);
        window.addEventListener('keyup', throttle(keyUpListener, 8), false);
        window.addEventListener('resize', resizeCanvas, false);
        return () => {
            window.removeEventListener('resize', resizeCanvas, false);
            //window.removeEventListener('keydown', keyDownListener);
            window.removeEventListener('keydown', throttle(keyDownListener, 8), false);
            //window.removeEventListener('keyup', keyUpListener);
            window.removeEventListener('keyup', throttle(keyUpListener, 8), false);
            //window.removeEventListener('keydown', throttle(handleKeyDown, 3), false);
            /*  window.removeEventListener('keydown',function (e) {
                 if (e.key in keys) {
                     keys[e.key] = false;
                 }
             });
             window.removeEventListener('keyup',function (e) {
                 if (e.key in keys) {
                     keys[e.key] = false;
                 }
             }); */
        };
    }, [canvasRef]);








    return <>
        {/* <div className="game-container" style={{ position: 'relative', width: 'fit-content', height: '100%' }}>
            <>

                {Object.keys(ballons).map((key: any, index: any) => {
                    return ballons[key].clear ? null :
                        <div className={`fading-div ${ballons[key].fadeOut ? 'fade-out' : ''}`} key={index} style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', position: 'absolute', width: '200px', height: '50px', top: ballons[key].player.y - 140, left: ballons[key].player.x - 110, zIndex: 20 }}>
                            <img width={200} src={require("./../assets/ballon/1.png")} alt="" />
                            <img width={200} src={require("./../assets/ballon/2.png")} alt="" />
                            <img width={200} src={require("./../assets/ballon/3.png")} alt="" />
                            <div style={{ position: 'relative', top: '-140px', color: 'black' }}>{ballons[key].message}</div>
                        </div>


                }
                )



                }
            </>



        </div> */}
        <div ref={gameDivRef} className="land">

            <div className="land-img">

                <canvas
                    ref={canvasRef}
                    style={{
                        width: 'calc(576px * 2) !important',
                        height: 'calc(288px * 2) !important',
                        imageRendering: '-webkit-optimize-contrast',
                    }}
                />
            </div>
            <div className={`rain-img${!isRain ? '-out' : ''}`}></div>

        </div>



    </>;
});

export default Canvas;
