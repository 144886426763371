import './metamask.styles.css'

interface AccountInfoProps {
  account: string;
}

export default function AccountInfo({ account }: AccountInfoProps) {
  return (
    <button className="btn-primary">
      <img src="/metamask.svg" width={20} className="me-3" alt="MetaMask" />
      {account.substring(0, 6)}...{account.substring(account.length - 4)}
    </button>
  );
}