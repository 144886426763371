import React, { useState, useEffect, useRef } from 'react';
import Notifications from './notifications/Notifications';
import { Button, Card, CardContent, IconButton, Input } from '@mui/material'

import {Close, Chat as ChatIcon} from '@mui/icons-material';
interface ChatProps {
  user: string;
  socket: any;
  color: any;
  canvasRef: any;
}
const Chat = ({ user, socket, color, canvasRef }: ChatProps) => {
  let entrou = false;
  const messagesEndRef = useRef<any>(null);
  const [messages, setMessages] = useState<any>([]);

  const [newMessage, setNewMessage] = useState('');
  const [chatVisible, setChatVisible] = useState(true);


  useEffect(() => {
    socket.on('message', (message: any) => {
      setMessages([...messages, message]);

      if (canvasRef.current) {
        //canvasRef.current.makeBallonPosition(message);
      }
    });
    return () => {
      socket.off('connect');
      socket.off('message');
      
    };
  },[messages])
  useEffect(() => {
   

    

    const handleKeyDown = (event: { key: string; }) => {
      
      if (event.key === 'Enter') {
        sendMessage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);



    return () => {
     
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [newMessage]);



  useEffect(() => {
    if (user.length && !entrou) {
      entrou = true;
      const textMessage = user + ': Entrou!'
      const objToSend = { message: textMessage, color, user }
      setTimeout(() => {
        socket.emit('message', objToSend);
      }, 1000)
    }
  }, [user]);
  useEffect(() => {
    
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);


  const sendMessage = () => {
    if (!newMessage) {
      const notData = {
        type: 'success',
        message: `Digite uma mensagem!`,
        title: 'Lobbi',
        timeOut: 2000,
        callback: () => { },
        priority: true,
      }

      Notifications(notData)

      return;
    }
    const textMessage = user + ': ' + newMessage
    const objToSend = { message: textMessage, color, user }
    socket.emit('message', objToSend);
    setNewMessage('');
  };

  const handleChat = ()=>{
    const newValue = chatVisible ?  false : true
    setChatVisible(newValue)
  }

  return (
    <div className='chatContainer'>
      {chatVisible?
     /*  <Button onClick={handleChat} style={{position:'relative', color: 'white',top:'10px', right:'-120px',fontSize:'10px'}}>
             <IconeClose />
            </Button> */
            
            <IconButton style={{position:'relative', color: 'white',top:'10px', right:'-140px',fontSize:'10px'}} onClick={handleChat} aria-label="share">
              <Close />
            </IconButton> 
            :null}
      <div className={chatVisible ? "chat" : "chat chat-retrieve"} style={{}}>
      
        {messages.map((msg: any, index: any) => (
          <div key={index}>{msg.message}</div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <Card className="w-full max-w-md mx-auto">
        <CardContent className="flex flex-col items-center space-y-4 p-4">
        {!chatVisible ? 
        <IconButton  onClick={handleChat} aria-label="ChatIcon">
              <ChatIcon />
            </IconButton> :
            <>
            <Input
              type="text"
              value={newMessage}
              onChange={(e:any) => {
                setNewMessage(e.target.value)
              }}
              placeholder="Digite sua mensagem"
              className="flex-grow"
            />
            <Button onClick={sendMessage}>Enviar</Button>
            </>}
            
            
          
        </CardContent>
      </Card>
      {/*  <div className="input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={sendMessage}>Send</button>
      </div> */}
    </div>
  );
};

export default Chat;