import './metamask.styles.css'

interface DisconnectButtonProps {
  onClick: () => void;
}

export default function DisconnectButton({ onClick }: DisconnectButtonProps) {
  return (
    <button className="btn-primary" onClick={onClick}>
      Desconectar
    </button>
  );
}