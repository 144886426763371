// Conference.js
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Peer from 'peerjs';
import { Button } from '@mui/material';

interface ChatProps {
    user: string;
    socket: any;
    color: any;
    canvasRef: any;
}
var localStream: any
var currentCall: any
var con: any
var chamou: boolean = false
var guest:boolean= false
var peer: any
var roomName = ''
var peerIdLocal = ''
const Conference = forwardRef(({ user, socket, color, canvasRef }: ChatProps, ref) => {
    

    const videoRef: any = useRef(null);
    console.log('entrou no conference')
    if(!peer)
        peer= new Peer();

    const [hasConference, setHasConference] = useState<any>(false);
    

    useImperativeHandle(ref, () => ({
        joinPeer,
        leavePeer
    }));


    function leavePeer(params: any) {
        console.log('chamou leave peer', currentCall)
        if (currentCall) {
            console.log('clear current call')
            currentCall.close();
        }


        if (localStream) {
            console.log('clear local stream')
            localStream.getAudioTracks().forEach(function (track: { stop: () => void; }) {
                track.stop();
            });

            localStream.getVideoTracks().forEach(function (track: { stop: () => void; }) {
                track.stop();
            });
            localStream.getTracks().forEach((track: { stop: () => void; }) => {
                track.stop();
            });
        }
        localStream = null;
        guest = false
        /* if(con) {
            console.log('close con  &&&&&&&&&&&&&&&&&&&&&')
            con.close()
        } */
        // stop only audio
        //localStream.getAudioTracks()[0].stop();
        // stop only video
        //localStream.getVideoTracks()[0].stop();
        if(videoRef.current){
            //videoRef.current.pause()
            videoRef.current.srcObject = null;
        }
        
        roomName=''
        currentCall=''
        localStream=''
        setHasConference(false)


    }
    function joinPeer(params: any) {
        console.log('chamou join peer', params)
        if(roomName == params.roomName&&localStream)return;
        console.log('passou')
        roomName = params.roomName;
        guest = params.guest
        if(guest){
            const obj: any = { roomName, peerId: peerIdLocal }
            //console.log('acionando a conexao', obj)
            socket.emit('peerconnected', obj);
        }
        

    }

    useEffect(() => {

        socket.on('user-connected', (clientId: string) => {


            
            setHasConference(true)


        });

        peer.on("connection", (connection: any) => {
            console.log('conectou')
            con = connection;
        });

        peer.on('open', (id: string) => {
            peerIdLocal = id

            console.log('My peer ID is: ' + id);
        })

        peer.on('call', (call: any) => {
            setHasConference(true)
            currentCall = call
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {

                var options: any = {
                    'constraints': {
                        'mandatory': {
                            'OfferToReceiveAudio': true,
                            'OfferToReceiveVideo': true
                        }
                    }
                }
                console.log("on call")

                call.answer(new MediaStream(), options); // Answer the call with an A/V stream.
                call.on('stream', function (remoteStream: any) {
                    videoRef.current.srcObject = remoteStream;
                });
                return;
            }


            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(stream => {
                    console.log('pegando stream do convidado')
                    localStream = stream;
                    call.answer(stream); // Answer the call with an A/V stream.
                    call.on('stream', function (remoteStream: any) {
                        videoRef.current.srcObject = remoteStream;
                    });


                })
                .catch(err => {
                    var options: any = {
                        'constraints': {
                            'mandatory': {
                                'OfferToReceiveAudio': true,
                                'OfferToReceiveVideo': true
                            }
                        }
                    }

                    call.answer(new MediaStream(), options); // Answer the call with an A/V stream.
                    call.on('stream', function (remoteStream: any) {
                        videoRef.current.srcObject = remoteStream;
                    });




                    //console.log(err)
                });



        });


        socket.on('peerconnected', (peerId: string) => {
            //console.log('entrou no stream ===> ', peerId)
            setHasConference(true)
            if (peerIdLocal == peerId || guest) {
                return;
            }

            
            /* var getUserMedia = navigator.mediaDevices.getUserMedia ||
                   navigator.mediaDevices.webkitGetUserMedia ||
                   navigator.mediaDevices.mozGetUserMedia; */
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {

                var options: any = {
                    'constraints': {
                        'mandatory': {
                            'OfferToReceiveAudio': true,
                            'OfferToReceiveVideo': true
                        }
                    }
                }
                let call = peer.call(peerId, new MediaStream(), options);
                //console.log("call", call)
                currentCall = call
                call.on('stream', (remoteStream: any) => {

                    videoRef.current.srcObject = remoteStream;
                });
                //console.error('getUserMedia não é suportado pelo navegador.');
                return;
            }
            try {
                navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                    .then(stream => {
                        localStream = stream;

                        videoRef.current.srcObject = stream;
                        const call = peer.call(peerId, stream);
                        //console.log("call", call)
                        currentCall = call
                        //call.answer(stream)
                        call.on('stream', (remoteStream: any) => {
                            if(videoRef.current){
                                videoRef.current.srcObject = remoteStream;
                            }
                            
                        });
                    })
                    .catch(err => {
                        var options: any = {
                            'constraints': {
                                'mandatory': {
                                    'OfferToReceiveAudio': true,
                                    'OfferToReceiveVideo': true
                                }
                            }
                        }
                        let call = peer.call(peerId, new MediaStream(), options);
                        //console.log("call", call)
                        currentCall = call
                        //call.answer(new MediaStream())
                        call.on('stream', (remoteStream: any) => {
                            //alert('remote stream')
                            videoRef.current.srcObject = remoteStream;
                        });
                        //console.error('Failed to get local stream', err);
                        //console.error('getUserMedia não é suportado pelo navegador.');
                        return;

                    });
            } catch (error: any) {
                //console.log(error)
            }

        });







        return () => {
            console.log('disconnect')
            socket.off('peerconnected');
            socket.off('peerconnected');
        };
    }, [])



    return (
        <>
            {
                hasConference ?
                    <div style={{ position: 'absolute', top: 'calc(100vh/2 - 240)', left: 'calc(100vw/2 - 256px)', width: '320px', height: '240px' }}>
                        <video width={320} height={240} ref={videoRef} autoPlay />
                    </div> : null
            }

            {/* <Button onClick={joinPeer}>Iniciar</Button> */}
        </>


    );
});

export default Conference;
