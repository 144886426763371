import React, { useEffect, useRef, useState } from 'react';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './App.css';
import Chat from './components/Chat'
import Conference from './components/conference/Conference'
import Canvas from './components/Canvas';
import io from 'socket.io-client';
import Metamask from './components/metamask/Metamask';

//const socket = io('https://ghostchat-30619341659.southamerica-east1.run.app');
const socket = io('https://ghostchat-30619341659.southamerica-west1.run.app');
//const socket = io('http://192.168.0.2:8080');
var sessionID:any=null
var socketConnection = socket.connect();
socketConnection.on('connect', function() {
   sessionID = socket.id; //
  //console.log('sessionID',sessionID)
});

function App() {
  const canvasRef = useRef(null);
  const confRef = useRef(null);
  const [user, setUser] = useState('');
  const [color, setColor] = useState('');
  const [chat, setChat] = useState(false);
  useEffect(() => {
    const handleKeyDown = (event: { key: string; }) => {
      if (event.key === 'Enter' && !chat) {
        console.log('entrou aqui')
        startChat();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [chat]);
  const startChat = ()=>{
    setChat(true);
  }
  return (
    <div className="App">
      <NotificationContainer/>
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <Metamask />
       {
        user&&chat&&sessionID ? 
        <>
        <Canvas ref={canvasRef} user={user} socket={socket} color={color} confRef={confRef} />
        <Chat canvasRef={canvasRef} user={user} socket={socket} color={color}  />
        <Conference ref={confRef} canvasRef={canvasRef} user={user} socket={socket} color={color}  />
        </>
         : 
        <div className='nick-container'>
        <input type='color' value={color}
          onChange={(e) => setColor(e.target.value)}
          />
        
        
        <span className='subtitle'>
          NickName
        </span>
        
        <input
          
          type="text"
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
        <button onClick={startChat}>Entrar</button>
      
        </div>
       }
      </header>

    </div>
  );
}

export default App;
