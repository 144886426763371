import './metamask.styles.css'

interface LoginButtonProps {
  onClick: () => void;
}

export default function LoginButton({ onClick }: LoginButtonProps) {
  return (
    <button className="btn-primary" onClick={onClick}>
      Conectar MetaMask
    </button>
  );
}