import Web3 from "web3";

interface LoginResponse {
  account: string;
}

export default async function doLogin(): Promise<LoginResponse> {
  try {
    if (!window.ethereum) {
      throw new Error("MetaMask não está instalada!");
    }

    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.requestAccounts(); 
    if (!accounts || !accounts.length) {
      throw new Error("MetaMask não foi autorizada!");
    }

    const account = accounts[0];
    localStorage.setItem("wallet", account);
    return { account };
  } catch (err) {
    throw alert (err);
  }
}