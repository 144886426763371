import Web3 from "web3";

interface DisconnectResponse {
  success: boolean;
}

export default async function disconnect(): Promise<DisconnectResponse> {
  new Web3(window.ethereum);
  try {

    await window.ethereum.request({
      method: "wallet_revokePermissions",
      params: [
        {
          eth_accounts: {}
        }
      ]
    });
    localStorage.removeItem("wallet");
    return { success: true };
  } catch (err) {
    throw err;
  }
}